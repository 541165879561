import React from 'react';
import { IResource } from '../../interfaces/resource';
import CtaLink from '../atoms/CtaLink';
import ResourceTile from '../atoms/ResourceTile';
import HorizontalResourceTile from '../molecules/HorizontalResourceTile';

interface LatestBlogPostsProps {
  title: string;
  resources: Array<IResource>;
}

const LatestBlogPosts: React.FC<LatestBlogPostsProps> = ({
  title,
  resources,
}) => (
  <section className="u-container my-18 lg:my-36">
    <div className="items-center justify-between mb-10 space-y-3 md:flex md:space-y-0">
      <h2
        className="text-4xl font-semibold lg:text-6xl"
        dangerouslySetInnerHTML={{ __html: title }}
      />
      <CtaLink to="/blog" label="View all" />
    </div>

    <div className="justify-between space-y-5 lg:flex lg:space-x-7 lg:space-y-0">
      <div className="lg:w-1/2">
        <ResourceTile {...resources[0]} large />
      </div>
      <div className="space-y-5 lg:w-1/2">
        {resources.slice(1).map((resource, index) => (
          <HorizontalResourceTile key={index} {...resource} />
        ))}
      </div>
    </div>
  </section>
);

export default LatestBlogPosts;
