import React from 'react';
import { IResource } from '../../interfaces/resource';
import CtaLink from '../atoms/CtaLink';
import ResourceTile from '../atoms/ResourceTile';

interface ResourceGridProps {
  title: string;
  url: string;
  resources: Array<IResource>;
  cols?: number;
}

const ResourceGrid: React.FC<ResourceGridProps> = ({
  title,
  url,
  resources,
  cols,
}) => (
  <section className="u-container my-18 lg:my-36">
    <div className="items-center justify-between mb-10 space-y-3 md:flex md:space-y-0">
      <h2
        className="text-4xl font-semibold lg:text-6xl"
        dangerouslySetInnerHTML={{ __html: title }}
      />
      <CtaLink to={url} label="View all" />
    </div>
    <div className="space-y-6 lg:flex lg:space-x-6 lg:space-y-0">
      {resources.map((resource, index) => (
        <div
          key={index}
          className={`w-full ${cols === 2 ? 'lg:w-1/2' : 'lg:w-4/12'}`}
        >
          <ResourceTile {...resource} />
        </div>
      ))}
    </div>
  </section>
);

export default ResourceGrid;
