import { graphql } from 'gatsby';
import moment from 'moment';
import React, { useState } from 'react';
import Layout from '../components/base/Layout';
import Seo from '../components/base/Seo';
import LatestBlogPosts from '../components/organisms/LatestBlogPosts';
import ResourceGrid from '../components/organisms/ResourceGrid';
import ResourceListHero from '../components/organisms/ResourceListHero';
import ResourcesHeader from '../components/organisms/ResourcesHeader';
import SearchResults from '../components/organisms/SearchResults';
import { useSearch } from '../hooks/use-search';

const LearnPage = ({ data }) => {
  const {
    wp: {
      options: {
        globalOptions: {
          resourceLabelsPlural: { blogPost, event, guide, newsItem },
        },
      },
    },
  } = data;

  const [query, setQuery] = useState('');
  const results = useSearch(data.siteSearchIndex.index, query);

  const latestPosts = [
    ...data.allBlogPosts.edges.map(({ node }) => node),
    ...data.allWpGuide.edges.map(({ node }) => node),
    ...data.allWpEvent.edges.map(({ node }) => node),
    ...data.allWpNewsItem.edges.map(({ node }) => node),
  ].sort((a, b) => (moment(a.dateGmt) < moment(b.dateGmt) ? 1 : -1));

  const resourceResults = [
    ...results
      .filter(({ nodeType }) => nodeType === 'Post')
      .map(
        ({ slug }) =>
          data.allBlogPosts.edges.find((post) => post.node.slug === slug)?.node,
      ),
    ...results
      .filter(({ nodeType }) => nodeType === 'NewsItem')
      .map(
        ({ slug }) =>
          data.allWpNewsItem.edges.find((post) => post.node.slug === slug)
            ?.node,
      ),
    ...results
      .filter(({ nodeType }) => nodeType === 'Guide')
      .map(
        ({ slug }) =>
          data.allWpGuide.edges.find((post) => post.node.slug === slug)?.node,
      ),
    ...results
      .filter(({ nodeType }) => nodeType === 'Event')
      .map(
        ({ slug }) =>
          data.allWpEvent.edges.find((post) => post.node.slug === slug)?.node,
      ),
  ].filter(Boolean);

  return (
    <Layout>
      <Seo post={data.data} />

      <ResourcesHeader activeSearchQuery={query} onQuery={setQuery} />

      {query.trim().length > 0 ? (
        <SearchResults resources={resourceResults} />
      ) : (
        <>
          <ResourceListHero
            {...latestPosts[0]}
            listTitle={data.data.learnListing.learnListingHero.heading}
          />

          <LatestBlogPosts
            title={blogPost}
            resources={data.allBlogPosts.edges
              .map(({ node }) => node)
              .slice(0, 4)}
          />
          <ResourceGrid
            title={guide}
            url="/guides/"
            resources={data.allWpGuide.edges
              .map(({ node }) => node)
              .slice(0, 3)}
          />
          <ResourceGrid
            title={newsItem}
            url="/news/"
            cols={2}
            resources={data.allWpNewsItem.edges
              .map(({ node }) => node)
              .slice(0, 3)}
          />
          <ResourceGrid
            title={event}
            url="/events/"
            resources={data.allWpEvent.edges
              .map(({ node }) => node)
              .slice(0, 3)}
          />
        </>
      )}
    </Layout>
  );
};

export const pageQuery = graphql`
  query LearnPageQuery {
    data: wpPage(slug: { eq: "learn" }) {
      title
      ...SEO
      learnListing {
        learnListingHero {
          heading
        }
      }
    }
    allBlogPosts: allWpPost(sort: { fields: dateGmt, order: DESC }) {
      edges {
        node {
          date
          dateGmt
          title
          nodeType
          slug
          excerpt
          featuredImage {
            node {
              ...Image
            }
          }
        }
      }
    }
    allWpEvent(sort: { fields: dateGmt, order: DESC }, limit: 3) {
      edges {
        node {
          title
          nodeType
          slug
          excerpt
          dateGmt
          featuredImage {
            node {
              ...Image
            }
          }
          event {
            eventDate
          }
        }
      }
    }
    allWpGuide(sort: { fields: dateGmt, order: DESC }, limit: 3) {
      edges {
        node {
          title
          nodeType
          slug
          excerpt
          dateGmt
          featuredImage {
            node {
              ...Image
            }
          }
        }
      }
    }
    allWpNewsItem(sort: { fields: dateGmt, order: DESC }, limit: 3) {
      edges {
        node {
          title
          nodeType
          slug
          excerpt
          dateGmt
          featuredImage {
            node {
              ...Image
            }
          }
        }
      }
    }
    wp {
      seo {
        contentTypes {
          post {
            schema {
              raw
            }
          }
        }
      }
      options {
        globalOptions {
          resourceLabelsPlural {
            blogPost
            event
            guide
            newsItem
          }
        }
      }
    }
    siteSearchIndex {
      index
    }
  }
`;

export default LearnPage;
